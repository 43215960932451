import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/header';

export default {
    name: "Home",
    data (){
        return{
            imageSource: require('@/assets/img/build-color/building-img-color1.png')
        }
    },
    methods: {
        changeSource: function() {
            //Your source-changing code here
        }
    },
    mounted () {
        document.title = "AGC - Home";
        AOS.init();
        resize();
        function resize(){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        }
// eslint-disable-next-line
        $('.m-search-BT').on('click',(evt)=> {
            $('.m-search-BT + .dp').toggle()
        });
        $('img[alt="color"]').on('click',(evt)=>{
            $('img[alt="color"].active').removeClass("active"); 
            $('#'+evt.target.id).addClass("active"); 
            // $('img[alt="building-img"]').attr('src',`/img/build-color/building-img-${evt.target.id}.png`); 
            this.imageSource = require(`@/assets/img/build-color/building-img-${evt.target.id}.png`)
        })
    },
    components: {
        Footer,Header
    }
};